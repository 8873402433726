import React, { FC } from 'react';
import FormItem from 'antd/lib/form/FormItem';

import 'jodit/build/jodit.min.css';

import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { messageErrorLength } from '../preHeaderMessageFields/validate-fields';
import {
  IPreHeaderRole,
  IPreHeaderRoleMessage,
  PreHeaderRoleListKey,
} from '../../models/preheader.models';

import {
  ContentEditableEvent,
  BtnBold,
  BtnLink,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import { removeHtmlTags } from '../../utils/cleanHtmlTags';

export interface IPreHeaderRoleMessageProps {
  message: IPreHeaderRoleMessage;
  preHeaderId?: string;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
  role: IPreHeaderRole;
}

const PreHeaderMessage: FC<IPreHeaderRoleMessageProps> = ({
  message,
  handleChange,
  role,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Fragment>
      <Title level={3}>*Message</Title>
      <Text>{formatMessage({ id: 'preHeader.maxMessageLength' })}</Text>
      <FormItem className="webeat-preheader-section-hero-input" colon={false}>
        <EditorProvider>
          <Editor
            containerProps={{
              style: {
                border: messageErrorLength(message).error
                  ? '1px solid red'
                  : '',
              },
            }}
            value={message.message}
            onChange={(event: ContentEditableEvent) => {
              handleChange(role.customerRole, !!role.active, {
                ...role,
                preHeaderMessages: role.preHeaderMessages!.map(
                  (m: IPreHeaderRoleMessage) => {
                    if (m.id === message.id) {
                      message.message = event.target.value;
                    }
                    return m;
                  },
                ),
              });
            }}>
            <Toolbar>
              <BtnBold />
              <BtnLink />
              <BtnUnderline />
            </Toolbar>
          </Editor>
          <p>
            Characters & white spaces: {removeHtmlTags(message.message).length}
          </p>
        </EditorProvider>
      </FormItem>
    </Fragment>
  );
};

export default PreHeaderMessage;
