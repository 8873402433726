export const removeHtmlTags = (text: string | null | undefined): string => {
  return text
    ? text
        .replace(/<[^>]+>/g, '')
        .replace(/<\/?[\w\s=".:;#-/]+>/gi, '')
        .replace(/&nbsp;/g, '')
        .trim()
    : '';
};

export const transformHtmlToString = (
  text: string | null | undefined,
): string => {
  return text
    ? text
        .replace(/<br\s*\/?>\s*$/gi, '')
        .replace(/<br\s*\/?>/gi, ' (line break) ')
        .replace(/<\/?[\w\s=".:;#-/]+>/gi, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/\s+/g, ' ')
        .trim()
        .replace(/\s+\(line break\)\s*$/, '')
    : '';
};
